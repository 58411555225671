* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Suisseintl book';
    src: url("./assets/fonts/Suisse\ Int\'l\ Book.woff2") format("woff2"), 
         url("./assets/fonts/Suisse\ Int\'l\ Book.woff") format("woff");
  }

@font-face {
    font-family: 'Suisseintl';
    src: url("./assets/fonts/Suisse Int'l Bold.woff2") format("woff2"), 
         url("./assets/fonts/Suisse Int'l Book.woff") format("woff");
  }

:root {
    --y: #fac670;
    --o: #f37925;
    --w: #fafafa;
    --b: #ead0a3;
    --l: #fcf3e4;
    --v: #c0a8e6;
    --lb: #f5e1be;
    --gb: #91c3ce;
    --dgb: #49a3a7;
    --blue: #ddd;
    --accent: #1b1a19;
    --index: calc(1vw + 1vh);
    --text: 'Suisseintl book',sans-serif;
    --bold: 'Suisseintl',sans-serif;
}
:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}
::placeholder {
    opacity: .4;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 0;
  }

body {
    font-family: var(--text);
    user-select: none;
    font-size: max(20px, var(--index));
    overflow-x: hidden;
    color: var(--accent);
}

a {
    text-decoration: none;
    color: var(--accent);
}

/*---
---------------- SECTIONS -------------
----------*/

section {
    width: 100vw;
    height: auto;
}

 .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
}

 .section_header {
    height:100vh;
    padding: calc(var(--index)*1.5);
 }

.title {
    font-family: var(--bold);
    font-size: calc(var(--index)*3.6);
    width: calc(var(--index)*20);
    line-height: calc(var(--index)*2.9);
    letter-spacing:  calc( var(--index)/10*-1);
    margin: var(--index) 0;
}

.title > span {
    color: var(--o);
    /* letter-spacing: calc( var(--index)/2); */
}

@media screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: 1fr;
     }
     .title {
        font-size: calc(var(--index)*4.5);
        width: calc(var(--index)*25);
        line-height: calc(var(--index)*4);
     }
}

 /* SECTION - BANNER */

#home {
    position: relative;
 }

.banner_header {
    padding: calc(var(--index)*0.8) calc(var(--index)*1.5) calc(var(--index)*1.5) calc(var(--index)*1.5);
    background-color: var(--y);
    box-sizing: border-box;
    display:grid;
    align-content: space-between;
 }

.loggin {
    position: absolute;
    top: calc(var(--index)*1.3);
    right:calc(var(--index)*1.5);
    color: var(--lb);
    cursor: pointer;
    font-family: var(--bold);
 }

.loggin_wrapper {
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index: 10;
    background-color: var(--accent);
    display: none;
    justify-content: center;
    align-content: center;
 }

.loggin_modal {
    width: calc(var(--index)*30);
    height: auto;
    background-color:var(--b);
    border-radius: calc(var(--index) * .2);
    padding: calc(var(--index)*.8);
    box-sizing: border-box;
    position: relative;
  }

.back {
    position: absolute;
    top: calc(var(--index)*1.6);
    right: calc(var(--index)*.8);
    font-size: calc(var(--index)*.8);
    cursor: pointer;
}

.loggin_header {
    font-size: calc(var(--index) * 2.1);
    font-family: var(--bold);
    color: var(--o);
}

.form__loggin {
    width:100%;
    height: auto;
    display: block;
    margin: calc(var(--index)*2) 0;
}

.form__loggin input {
    width: 100%;
    box-sizing: border-box;
    border-bottom: solid calc(var(--index)*.1) rgba(27, 26, 25, .2);
    padding: calc(var(--index) * .5) calc(var(--index) * 2);
}

.form__loggin_input {
    position: relative;
}

.form__loggin_input img {
    position: absolute;
    width: calc(var(--index)*1.4);
    top:50%;
    left:0;
    transform: translateY(calc(var(--index)*-1.2));
    opacity: .3;
}

.show_password {
    right:0;
    left:auto !important;
    cursor: pointer;
}


.form__loggin button {
    width: 100%;
    margin-top: calc(var(--index)*2);
    height:calc(var(--index)*4);;
}


.error-check {
    position: absolute;
    top: -15%;
    left: 5.5%;
    font-size: .9rem;
    color: var(--o);
}

.err-aut {
    position: fixed;
    top:2%;
    left: 50%;
    background-color: var(--o);
    transform: translateX(-50%);
    width: calc(var(--index)*30);
    color: var(--color-primary);
    display: none;
    font-size: 20px;
    place-items: center;
    margin: 20px auto;
    padding: 20px;
    box-sizing:border-box;
    border-radius: calc(var(--index)*.2);
}

.err-aut h2 {
    font-size: 30px;
    margin-bottom: 10px;
}

 .banner_header__text {
    width: calc(var(--index)*15);
    font-family: var(--text);
    line-height: calc(var(--index)*1.1);
 }

.logo {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.logo_text {
    font-size: calc(var(--index)*1.6);
    font-family: var(--bold);
    cursor: pointer;
    color: var(--text);
    letter-spacing: calc(var(--index) * -.17);
}

.logo_image img {
    width: calc(var(--index)*2.2);
}

.l_letter {
    opacity: 0;
    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    display: inline-block;
    position: relative;
}

.l_letter:nth-of-type(2) {
    animation-delay: .1s;
}
.l_letter:nth-of-type(3) {
    animation-delay: .2s;
}
.l_letter:nth-of-type(4) {
    animation-delay: .3s;
}
.l_letter:nth-of-type(5) {
    animation-delay: .4s;
}
.l_letter:nth-of-type(6) {
    animation-delay: .5s;
}
.l_letter:nth-of-type(7) {
    animation-delay: .6s;
}
.l_letter:nth-of-type(8) {
    animation-delay: .7s;
}
.l_letter:nth-of-type(9) {
    animation-delay: .8s;
}

 .banner_content {
    background-image: url(./assets/images/bg-banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--accent);
    display: grid;
    place-content: center;
 }

 .btn_banner {
    width: 100%;
    font-size: calc(var(--index)*1.6);
    color: var(--b);
    transition: transform .4s cubic-bezier(0.075, 0.82, 0.165, 1);
    will-change: transform;
    user-select: none;
}

.btn_banner:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
    .loggin_header {
        margin-top: calc(var(--index)*2);
    }
    .loggin_err{
        font-size: calc(var(--index) * 1.5);
    }
    .form__loggin {
        margin: calc(var(--index)*3) 0;
    }
    .form__loggin input {
        margin-bottom: calc(var(--index) * 2);
    }
    .form__loggin_input img {
        transform: translateY(calc(var(--index)*-1.7));
    }
    .back {
        top: calc(var(--index)*1.6);
        right: calc(var(--index)*.8);
        font-size: calc(var(--index)*1.2);
    }
    .banner_header {
        height:65vh;
    }
    .banner_content {
        height:50vh;
     }
     .banner_header__text {
        width: calc(var(--index)*20);
        line-height: calc(var(--index)*1.5);
     }
     .logo_text {
        display: none;
     }
     .loggin {
        color: var(--accent);
        font-size: calc(var(--index) * 1.6);
        top: calc(var(--index)*.8);
        right:calc(var(--index)*1.5);
     }
}

/* SECTION - SERVICES */

.services {
    background-color: var(--w);
    padding: calc(var(--index)*7) calc(var(--index)*1.5) calc(var(--index)*1.5) calc(var(--index)*1.5);
    display: grid;
    height:100vh;
    overflow: scroll;
    box-sizing:border-box;
    position: relative;
}

.services::-webkit-scrollbar {
    width: 0;
  }

.services_item {
    font-size: calc(var(--index)*2.1);
    font-family: var(--bold);
    border-radius: calc(var(--index));
    padding: var(--index) calc(var(--index)*2);
    margin:calc(var(--index)/5) 0;
    line-height: calc(var(--index)*2.9);
    letter-spacing:  calc( var(--index)/10*-1);
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    transition: background .4s ease-in;
    will-change: transition;
}
.services_item > div {
    padding: calc( var(--index)/2) 0;
}
.services_item:hover {
    background: var(--blue);
}
.services_item img {
    width:calc(var(--index)*2.5);
    margin-right: var(--index);
}

.active {
    background-color: var(--gb);
}

.active:hover {
    background-color: var(--gb);
}

.services_txt {
    position:absolute;
    top: calc(var(--index)*1.5);
    left: calc(var(--index)*1.5);
}

.services_item__content {
    display: none;
    grid-template-areas: "first second" "third third";
    width: 100%;
    height: 100%; 
}

.active_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 3fr;
}

.cell {
    display: grid;
    place-items: center;
}

.cell_first {
    grid-area: first;
    background-color: var(--gb);
}

.cell_first img {
    width: calc(var(--index)*8);
}

.cell_second {
    grid-area: second;
    background-color: var(--o);
    font-size: calc(var(--index) * 2.1);
    font-family: var(--bold);
}

.cell_second span {
    text-align: center;
}

.cell_third {
    grid-area: third;
    width:100%;
    height: 100%;
    background-color: var(--y);
    padding: calc(var(--index)*1.5) ;
    box-sizing:border-box;
    align-content:center;
}

.cell_third h2 {
    font-family: var(--bold);
    margin-bottom: calc(var(--index)*1.5);
}

@media  screen and (max-width: 1024px) {
    .services_item {
        font-size: calc(var(--index)*2.3);
    }
    .active_content {
        min-height: 60vh;
    }
}

/*---
---------------- SECTION SOLUTIONS-------------
----------*/

.solutions_header {
    padding: calc(var(--index)*1.5) calc(var(--index)*1.5) calc(var(--index)*4.5) calc(var(--index)*1.5);
    background-color: var(--b);
    position: relative;
    box-sizing: border-box;
    display: grid;
    align-content:space-between;
    height: auto;
}

.solutions_header  h2 {
    position: sticky;
    top:calc(var(--index)*1.5);
}

.solutions_header__text {
    width: calc(var(--index) * 15);
    font-family: var(--text);
    line-height: calc(var(--index) * 1.1);
    margin-bottom: calc(var(--index)*-3);
}

.solutions {
    width: 100%;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: var(--index);
    height:100%;
    background-color: var(--lb);
    padding: calc(var(--index)*1.5);
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
}

.container_solution__items {
    width: 100%;
    height: calc(var(--index)*10);
    border-radius: calc(var(--index)*.2);
    overflow: hidden;
}

.container_solution__items img {
    height: 65%;
    max-width:80%;
    margin: calc(var(--index)*0.5)auto;
    display: block;
}

.container_solution__items > div {
    width: 90%;
    margin: calc(var(--index)*0.3)auto;
    color: var(--w);
    text-align: center;
    font-size: calc(var(--index)*1.5);
}

.financial {
    background-color: var(--gb);
    grid-area: 1/1/2/2;
    
}

.healthcare{
    background-color: var(--y);
    grid-area: 1/2/2/3;
}

.products{
    background-color: var(--v);
    grid-area: 2/2/3/3;
}

.resources{
    background-color: var(--o);
    grid-area: 2/3/2/4;
}

.technology {
    background-color: var(--y);
    grid-area: 3/1/4/2;
}

.education {
    background-color: var(--gb);
    grid-area: 3/2/4/3;
}

@media  screen and (max-width: 1024px) {
    .solutions_header  h2 {
        position: static;
        min-height: 50vh;
    }
    .solutions_header__text {
        width: calc(var(--index) * 20);
        line-height: calc(var(--index) * 1.5);
    }
}

/*---
---------------- SECTION ABOUT -------------
----------*/

.about_header {
    padding: calc(var(--index)*1.5) calc(var(--index)*1.5) calc(var(--index)*6) calc(var(--index)*1.5);
    background-color: var(--b);
    position: relative;
    box-sizing: border-box;
    display: grid;
    align-content:space-between;
    height: auto;
}

.about_header  h2 {
    position: sticky;
    top:calc(var(--index)*1.5);
}

.about_header__text {
    width: calc(var(--index) * 15);
    font-family: var(--text);
    line-height: calc(var(--index) * 1.1);
    margin-bottom: calc(var(--index)*-5);
}

.about_header__text span{
    display: inline-block;
    width: calc(var(--index) * 1);
    height: calc(var(--index) * 1);
    background-color: var(--o);
    margin-right: calc(var(--index) * .51);
}
.about_header__text p {
    display: inline-block;
}

.about_header__text span:nth-child(1){
    background-color: var(--v);
}

.about_header__text span:nth-child(3){
    background-color: var(--o);
}

.about_header__text span:nth-child(5){
    background-color: var(--dgb);
}

.about_header__text span:nth-child(7){
    background-color: var(--lb);
}

.about {
    background-color: var(--lb);
    /* padding: calc(var(--index)*1.5); */
    height: 80vh;
    /* box-sizing:border-box; */
    display: grid;
    align-content: end;
    justify-items: center;
    position: relative;
    overflow: hidden;
}

.about_rect {
    width:calc(var(--index)*15);
    height: calc(var(--index)*25);
    background-color: var(--y);
    border-radius: calc(var(--index)*5);
    top: 40%;
    left: 50%;
    transform: translate(-50%, -30%);
    position: absolute;
}

.about_circle {
    width:calc(var(--index)*10);
    height: calc(var(--index)*10);
    background-color: var(--o);
    border-radius: 50%;
    top: 60%;
    left: 30%;
    transform: translate(-50%, 10%);
    position: absolute;
    z-index: 1;
}

.about_image {
    height: calc(var(--index)*25);
    z-index: 2;
}

@media screen and (max-width: 1024px) {
.about_header {
    grid-area: 1/1/2/2;
}

.about_header h2{
    min-height:50vh;
    position: static;
}

.about {
    grid-area: 2/1/3/2;
    height: auto;
    background-color: var(--w);
}

.about_circle {
    width:calc(var(--index)*20);
    height: calc(var(--index)*20)
}

.about_rect {
    width:calc(var(--index)*25);
    height: calc(var(--index)*40);
}

.about_image {
    height: calc(var(--index)*40);
}

.about_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
}
}

@media screen and (max-width: 600px) {
    .about_rect {
        width:calc(var(--index)*25);
        height: calc(var(--index)*40);
    }
    }

/*---
---------------- SECTION CONTACT-------------
----------*/
.contact_header {
    padding: calc(var(--index)*1.5) calc(var(--index)*1.5) calc(var(--index)*4.5) calc(var(--index)*1.5);
    background-color: var(--b);
    position: relative;
    box-sizing: border-box;
    display: grid;
    align-content:space-between;
    height: auto;
}

.contact_header  h2 {
    position: sticky;
    top:calc(var(--index)*1.5);
}

.contact_header > .title > span {
    letter-spacing: calc(var(--index)*-.1);;
}

.contact_header__text {
    font-family: var(--bold);
    font-size: calc(var(--index) * 3.6);
    width: calc(var(--index) * 25);
    line-height: calc(var(--index) * 2.9);
    letter-spacing: calc( var(--index) / 10 * -1);
    margin-bottom: calc(var(--index)*-3);
}

.contact_header__text span {
    color: var(--o);
}

.section_form {
    padding: calc(var(--index)*1.5);
    background-color: var(--w);
}

form {
    width: 100%;
    height: 100%;
    display: grid;
    align-content:space-between;
    grid-gap:calc(var(--index)*5);
}

.input_contact {
    display: grid;
    padding: calc(var(--index)*.5);
    margin-bottom: calc(var(--index)*.5);
    border-radius: calc(var(--index)*.3);
    background-color: var(--lb);
}

label {
    display: block;
    color: var(--o);
    margin-bottom: calc(var(--index)*.2);
    font-family: var(--bold);
    font-size: calc(var(--index)*.8);
    padding-left: calc(var(--index)*.8);
}

input,
textarea {
    font-family: var(--bold);
    font-size: calc(var(--index)*1);
    padding: calc(var(--index)*.5) calc(var(--index)*.8); 
    border: 0 solid var(--accent);
    background-color: transparent;
    margin-bottom: calc(var(--index)*.8);
}

.input_contact:active, .input_contact:hover, .input_contact:focus {
    outline: calc(var(--index)*.15) solid var(--o);
}

.submit {
    width: 100%;
    display: grid;
    grid-template-columns: 57% 1fr;
    align-items:end;
}

.form_btn {
    width: calc(var(--index) * 10);
    height: calc(var(--index) * 5);
    border:none;
    background-color: var(--o);
    font-size: calc(var(--index) * 2.1);
    font-family: var(--bold);
    border-radius: calc(var(--index)*.2);
    cursor: pointer;
    transition: all .3s ease-in;
    justify-self: end;
    color: var(--accent);
}

.form_btn:hover {
    background-color: #f88b3f;
}

.label_checkbox {
    position: relative;
    font-family: var(--text);
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: var(--index);
    padding:0;
    align-items: center;
}

.label_checkbox > span {
    color: var(--accent);
}

.label_checkbox > span > a {
    text-decoration: underline;
}

.checkbox {
    width: calc(var(--index) * 3.5);
    height: calc(var(--index) * 3.5);
    background-color: var(--lb);
    border-radius: calc(var(--index)*.2);
    cursor: pointer;
}

.checkbox:active, .checkbox:hover, .checkbox:focus {
    outline: calc(var(--index)*.15) solid var(--o);
}

.checkbox_checked {
    background-image:url('./assets/images/checkmark.svg');
    background-size: 65%;
    background-position:center;
    background-repeat: no-repeat;
}

#checkbox {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(0%, -50%);
    opacity: 0;
    z-index: -1;
    margin: 0;
}

@media  screen and (max-width: 1024px) {
    .contact_header {
        height: auto;
    }
    .contact_header  h2 {
        top:0;
        position: static;
    }
    .contact_header__text  {
        font-size: calc(var(--index) * 4.5);
        width: calc(var(--index) * 30);
        line-height: calc(var(--index) * 4);
        margin-top:calc(var(--index) * 4);
    }
    .section_form {
        background-color: var(--y);
    }
    form {
        grid-gap: calc(var(--index)*1.5);
    }
    label {
        font-size: calc(var(--index)*1);
    }
    input,
    textarea {
        font-size: calc(var(--index)*1.5);
    }
    .submit{
        grid-template-columns: 1fr;
        grid-gap: calc(var(--index)*2);
    }
}

/*---
---------------- SECTION BLOCK -------------
----------*/

.section_block {
    background-color: var(--accent);
    min-height: 60vh;
}

.block_header {
    box-sizing:border-box;
    height: auto;
}

.block_header h2{
    color: var(--l);
    width: calc(var(--index)*30);
}

.block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: calc(var(--index)*1.5);
    box-sizing: border-box;
    align-content: center;
    align-items: center;
    grid-gap:calc(var(--index)*1);
}

.blocks_item {
    color: var(--b);
    border-radius: calc(var(--index) * 3);
    padding: calc(var(--index)*.8);
    box-sizing:border-box;
}

.blocks_item p {
    /* margin: calc(var(--index)*2) 0; */
    letter-spacing: calc( var(--index) * -.05);
    display: grid;
    width:100%;
    height: 100%;
    place-items: center;
}

.block div:nth-child(1) {
    background-color: var(--v);
    height: calc(var(--index)*10);
}

.block div:nth-child(2) {
    background-color: var(--dgb);
    height: calc(var(--index)*15);
}


.block div:nth-child(3) {
    background-color: var(--o);
    height: calc(var(--index)*20);
}


@media  screen and (max-width: 1024px) {
    .block {
        grid-template-columns: 1fr;
    }
    .block_header h2 {
        width: calc(var(--index)*25);
    }
    .blocks_item {
        width: 50vw;
        font-size: calc(var(--index) * 1.6);
        min-height: 18vh;
        padding: calc(var(--index)*1.2);
    }
    .block div:nth-child(2) {
        justify-self: end;
        height: calc(var(--index)*15);
    }
    /* .block div:nth-child(2) p {
        margin: calc(var(--index)*2) 0;
    }
    .block div:nth-child(3) p {
        margin: calc(var(--index)*2) 0;
    } */
    .block div:nth-child(3) {
        height: calc(var(--index)*15);
    }
    .block div:nth-child(1) {
        height: calc(var(--index)*15);
    }
}

/*---
---------------- SECTION WORKFLOW -------------
----------*/

.work_header {
    background-color: var(--w);
    padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
    box-sizing: border-box;
    align-content: space-between;
    display: grid;
    height: auto;
}

.work_header h2 {
    top: calc(var(--index) * 1.5);
    position: sticky;
}

.work_header__text {
    width: calc(var(--index) * 15);
    font-family: var(--text);
    line-height: calc(var(--index) * 1.1);
    margin-bottom: calc(var(--index) * -3);
}

.workflow {
    padding: calc(var(--index) * 1.5);
    background-color: var(--accent);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: repeat(6, 1fr);
    color: var(--lb);
    grid-column-gap:calc(var(--index) * 1);
    grid-row-gap:calc(var(--index) * 2);
    min-height:60vh;
}

.work_item__one{
    grid-area: 1/1/2/2;
    justify-self: end;
}

.work_item__two{
    grid-area: 2/3/3/4;
}

.work_item__three{
    grid-area: 3/1/4/2;
    justify-self: end;
    text-align: right;
}

.work_item__four{
    grid-area: 4/3/5/4;
}

.work_item__five{
    grid-area: 5/1/6/2;
    justify-self: end;
}

.work_item__six{
    grid-area: 6/3/7/4;
}

.work_item__block {
    width: calc(var(--index) * 3);
    height: calc(var(--index) * 3);
    border-radius: calc(var(--index) * .2);
    z-index: 1;
    color: var(--accent);
    font-family: var(--bold);
    font-size: calc(var(--index) * 1.5);
    display: grid;
    place-items: center;
}

.item_blick__one {
    background-color: var(--o);
    grid-area: 1/2/2/3;
}

.item_blick__two{
    background-color: var(--b);
    grid-area: 2/2/3/3;
}

.item_blick__three {
    background-color: var(--dgb);
    grid-area: 3/2/4/3;
}

.item_blick__four {
    background-color: var(--y);
    grid-area: 4/2/5/3;
}

.item_blick__five {
    background-color: var(--v);
    grid-area: 5/2/6/3;
}

.item_blick__six {
    background-color: var(--gb);
    grid-area: 6/2/7/3;
}

.work_item__line {
    grid-area: 1/2/7/3;
    justify-self: center;
    background-color: var(--o);
    width: calc(var(--index) * .2);
    height: 90%;
}

@media screen and (max-width: 1024px) {
    .workflow {
        grid-template-columns: auto auto 1fr;
        align-items: center
    }
    .work_header h2 {
        position: static;
        min-height: 50vh;
    }
    .work_header__text {
        width: calc(var(--index) * 20);
        line-height: calc(var(--index) * 1.5);
    }
    .work_item__one{
        grid-area: 1/3/2/4;
        justify-self: start;
    }
    .work_item__three{
        grid-area: 3/3/4/4;
        justify-self: start;
        text-align: left;
    }
    .work_item__five{
        grid-area: 5/3/6/4;
        justify-self: start;
    }
}


/*---
---------------- SECTION CERTIFICATIONS -------------
----------*/
.certifications_header{
    box-sizing: border-box;
    height: auto;
    align-content: space-between;
    display: grid;
    padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
}

.certifications_header h2{
    top: calc(var(--index) * 1.5);
    position: sticky;
}
.certification_header__text {
    width: calc(var(--index) * 15);
    font-family: var(--text);
    line-height: calc(var(--index) * 1.1);
    margin-bottom: calc(var(--index) * -3.5);
    color: var(--w);
}
.wrapper_certifications {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: calc(var(--index) * .8);
    padding: calc(var(--index) * 1.5);
    box-sizing: border-box;
    height: 70vh;
    overflow: scroll;
}
.block_certificate {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items:start;
    grid-gap: calc(var(--index) * .3);
}

.check_certificate {
    width: calc(var(--index) * 2.5);
    height: calc(var(--index) * 2.5);
    background-color: var(--o);
    border-radius: calc(var(--index) * .2);
    display: grid;;
    place-items: center;
}

.certificate {
    color: var(--lb);
}

.certificate_check {
    width:60%;
}

@media screen and (max-width: 600px) {
    .wrapper_certifications {
        grid-template-columns: 1fr ;
    }
}

@media screen and (max-width: 1024px) {
.certifications_header {
    background-color: var(--y);
}

.certifications_header h2 {
    min-height: 50vh;
    position: static;
    color: var(--accent);
}
.certification_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
    color: var(--accent);
}
}
/*---
---------------- FOOTER -------------
----------*/

footer {
    height: auto;
    width: 100%;
    overflow: hidden;
}

.c_footer {
    min-height: 60vh;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}

.c_footer__block {
    display: grid;
    align-content:space-between;
    justify-items: start;
    padding: calc(var(--index)*1.5);
}

.c_footer__block__header {
    margin: 0;
}

.c_footer__block__sub {
    font-style: italic;
    font-family: var(--bold);
    font-size: calc(var(--index)*1.3);
}

.contacts {
    background-color: var(--v);
}

.contacts .c_footer__block__sub {
    color: var(--l);
}

.contacts_item img {
    width: calc(var(--index)*4.6);
}

.email {
    font-family: var(--bold);
    font-size: calc(var(--index)*1.8);
    line-height: calc(var(--index)*1.4);
}

.social {
    background-color: var(--b);
}

.social  .c_footer__block__sub {
    color: var(--dgb);
}

.social_item img {
    width: calc(var(--index)*3);
    margin-top: calc(var(--index)/-2);
}

.social_media {
    display: flex;
    flex-direction: row;
    grid-column-gap: var(--index);
    align-items:flex-start;
}

.social_item_icon {
    width: calc(var(--index)*2);
    height: calc(var(--index)*2);
    border: solid calc(var(--index)/5) var(--accent);
    border-radius: calc(var(--index)/5);
    display: flex;
    align-items:center;
}

.social_item_icon img {
    width: calc(var(--index)*2);
    height: calc(var(--index)*2);
    display: block;
    margin:0;
}

#linkedin {
    width: calc(var(--index)*1.5);
    height: calc(var(--index)*1.5);
    margin: 0 auto;    
}

.m_footer {
    height: 80vh;
    background-color: var(--l);
    display: grid;
    align-content:space-between;
}

.m_footer__nav {
    display: flex;
    justify-content: space-between;
    padding: calc(var(--index)*1.5);
}

.m_footer__letter {
    padding: calc(var(--index)*1.5) 0;
    box-sizing:border-box;
    display: flex;
    justify-content:center;
    width:100vw;
} 

.footer_link {
    font-family: var(--bold);
    font-size: calc(var(--index)*1.6);
    line-height: calc(var(--index)*1.8);
    letter-spacing: calc(var(--index)/-20);
    text-decoration: underline;
}

.letter {
    position: relative;
    display: inline-block;
    font-family: var(--bold);
    font-size: 20vw;
    letter-spacing: calc(var(--index)*-.1);
    line-height: calc(var(--index)*10);

    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
}

.anim {
    animation-name: animate;
}

.letter:nth-of-type(2) {
    animation-delay: .1s;
}
.letter:nth-of-type(3) {
    animation-delay: .2s;
}
.letter:nth-of-type(4) {
    animation-delay: .3s;
}
.letter:nth-of-type(5) {
    animation-delay: .4s;
}
.letter:nth-of-type(6) {
    animation-delay: .5s;
}
.letter:nth-of-type(7) {
    animation-delay: .6s;
}
.letter:nth-of-type(8) {
    animation-delay: .7s;
}
.letter:nth-of-type(9) {
    animation-delay: .8s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: translate3d(0, 50%, 0) rotateX(-90deg);
    }
    50% {
        opacity: 1;
        transform: translate3d(0, -10%, 0) rotateX(0deg);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0%, 0) rotateX(0deg)
    }
}

@media  screen and (max-width:1024px) {
    .c_footer {
        grid-template-columns: 1fr;
        min-height: 100vh;
    }
    .email {
        font-size: calc(var(--index)*2.7);
    }
    .contacts_item img {
        width: calc(var(--index)*7.5);
    }
    .social_item img {
        width: calc(var(--index)*5);
    }
    .social_item_icon {
        width: calc(var(--index)*4);
        height: calc(var(--index)*4);
    }
    .social_item_icon img {
        width: calc(var(--index)*4);
        height: calc(var(--index)*4);
    }
    #linkedin {
        width: calc(var(--index) * 2.2);
        height: calc(var(--index) * 2.2);
    }
    .c_footer__block__sub {
        font-size: calc(var(--index)*1.8);
    }
    .m_footer {
        height: auto;
        grid-gap: calc(var(--index)*5);
    }
    .m_footer__nav {
        column-count: 2;
        display: block;
    }
    .footer_link {
        line-height: calc(var(--index)*3);
    }
    .letter {
        line-height: calc(var(--index)*6);
    }
        
}

/*---
---------------- PAGE PRIVACY -------------
----------*/

.privacy_wrapper {
    background-color: var(--l);
    min-height: 100vh;
    padding: calc(var(--index) * .8) calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 1.5);
}

.privacy {
    margin: calc(var(--index) * 5) auto ;
    width: min(750px , 80vw);
}
.breadcrumb div {
    display: inline;
}
.br_home a {
    color: var(--o);
    text-decoration: underline;
}
.br {
    opacity:.5;
}

.privacy .title {
    width: auto;
    margin: calc(var(--index)*2) 0 calc(var(--index)*3) 0;
}

.privacy h3 {
    margin: calc(var(--index) * 1.2) 0 calc(var(--index) * 1) 0;
}

.privacy p {
    margin: calc(var(--index) * .8) 0;
}

/*---
---------------- PAGE 404 -------------
----------*/

.wrapper_error {
    width:100vw;
    height:100vh;
    background-color: var(--y);
    box-sizing:border-box;
    padding: calc(var(--index)*1.5);
    display: grid;
    align-content: center;
}

.error{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.error_title {
    font-family: var(--bold);
    font-size: calc(var(--index)*7);
}

.error_image img{
    height: calc(var(--index)*26);
}

.error_btn {
    width: calc(var(--index)*15);
    height: calc(var(--index)*5);
    background-color: var(--o);
    font-size: calc(var(--index)*2.1);
    font-family: var(--bold);
    border-radius: calc(var(--index)*.2);
    cursor: pointer;
    border: none;
    justify-self: end;
    transition: all .3s ease-in;
    display: grid;
    place-items: center;
    letter-spacing: calc( var(--index) / 10 * -1);
}

@media screen and (max-width: 720px) {
    .error{
        grid-template-columns: 1fr;
        grid-gap: calc( var(--index) *3);
    }
    .error_image {
        display: grid;
        justify-items: center;
    }
    .error_image img{
        height: 40vh;
    }
}
