* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Suisseintl book;
  src: url("Suisse Int'l Book.38bb7cf5.woff2") format("woff2"), url("Suisse Int'l Book.9a4eda3b.woff") format("woff");
}

@font-face {
  font-family: Suisseintl;
  src: url("Suisse Int'l Bold.d621e573.woff2") format("woff2"), url("Suisse Int'l Book.9a4eda3b.woff") format("woff");
}

:root {
  --y: #fac670;
  --o: #f37925;
  --w: #fafafa;
  --b: #ead0a3;
  --l: #fcf3e4;
  --v: #c0a8e6;
  --lb: #f5e1be;
  --gb: #91c3ce;
  --dgb: #49a3a7;
  --blue: #ddd;
  --accent: #1b1a19;
  --index: calc(1vw + 1vh);
  --text: "Suisseintl book", sans-serif;
  --bold: "Suisseintl", sans-serif;
}

:active, :hover, :focus {
  outline-offset: 0;
  outline: 0;
}

::placeholder {
  opacity: .4;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;
}

body {
  font-family: var(--text);
  -webkit-user-select: none;
  user-select: none;
  font-size: max(20px, var(--index));
  color: var(--accent);
  overflow-x: hidden;
}

a {
  color: var(--accent);
  text-decoration: none;
}

section {
  width: 100vw;
  height: auto;
}

.grid {
  grid-template-columns: 1fr 1fr;
  place-content: center;
  display: grid;
}

.section_header {
  padding: calc(var(--index) * 1.5);
  height: 100vh;
}

.title {
  font-family: var(--bold);
  font-size: calc(var(--index) * 3.6);
  width: calc(var(--index) * 20);
  line-height: calc(var(--index) * 2.9);
  letter-spacing: calc(var(--index) / 10 * -1);
  margin: var(--index) 0;
}

.title > span {
  color: var(--o);
}

@media screen and (width <= 1024px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: calc(var(--index) * 4.5);
    width: calc(var(--index) * 25);
    line-height: calc(var(--index) * 4);
  }
}

#home {
  position: relative;
}

.banner_header {
  padding: calc(var(--index) * .8) calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 1.5);
  background-color: var(--y);
  box-sizing: border-box;
  align-content: space-between;
  display: grid;
}

.loggin {
  top: calc(var(--index) * 1.3);
  right: calc(var(--index) * 1.5);
  color: var(--lb);
  cursor: pointer;
  font-family: var(--bold);
  position: absolute;
}

.loggin_wrapper {
  z-index: 10;
  background-color: var(--accent);
  place-content: center;
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.loggin_modal {
  width: calc(var(--index) * 30);
  background-color: var(--b);
  border-radius: calc(var(--index) * .2);
  padding: calc(var(--index) * .8);
  box-sizing: border-box;
  height: auto;
  position: relative;
}

.back {
  top: calc(var(--index) * 1.6);
  right: calc(var(--index) * .8);
  font-size: calc(var(--index) * .8);
  cursor: pointer;
  position: absolute;
}

.loggin_header {
  font-size: calc(var(--index) * 2.1);
  font-family: var(--bold);
  color: var(--o);
}

.form__loggin {
  margin: calc(var(--index) * 2) 0;
  width: 100%;
  height: auto;
  display: block;
}

.form__loggin input {
  box-sizing: border-box;
  border-bottom: solid calc(var(--index) * .1) #1b1a1933;
  padding: calc(var(--index) * .5) calc(var(--index) * 2);
  width: 100%;
}

.form__loggin_input {
  position: relative;
}

.form__loggin_input img {
  width: calc(var(--index) * 1.4);
  transform: translateY(calc(var(--index) * -1.2));
  opacity: .3;
  position: absolute;
  top: 50%;
  left: 0;
}

.show_password {
  cursor: pointer;
  right: 0;
  left: auto !important;
}

.form__loggin button {
  margin-top: calc(var(--index) * 2);
  height: calc(var(--index) * 4);
  width: 100%;
}

.error-check {
  color: var(--o);
  font-size: .9rem;
  position: absolute;
  top: -15%;
  left: 5.5%;
}

.err-aut {
  background-color: var(--o);
  width: calc(var(--index) * 30);
  color: var(--color-primary);
  box-sizing: border-box;
  border-radius: calc(var(--index) * .2);
  place-items: center;
  margin: 20px auto;
  padding: 20px;
  font-size: 20px;
  display: none;
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
}

.err-aut h2 {
  margin-bottom: 10px;
  font-size: 30px;
}

.banner_header__text {
  width: calc(var(--index) * 15);
  font-family: var(--text);
  line-height: calc(var(--index) * 1.1);
}

.logo {
  grid-template-columns: auto auto;
  place-content: center space-between;
  align-items: center;
  display: grid;
}

.logo_text {
  font-size: calc(var(--index) * 1.6);
  font-family: var(--bold);
  cursor: pointer;
  color: var(--text);
  letter-spacing: calc(var(--index) * -.17);
}

.logo_image img {
  width: calc(var(--index) * 2.2);
}

.l_letter {
  opacity: 0;
  animation-duration: .6s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  display: inline-block;
  position: relative;
}

.l_letter:nth-of-type(2) {
  animation-delay: .1s;
}

.l_letter:nth-of-type(3) {
  animation-delay: .2s;
}

.l_letter:nth-of-type(4) {
  animation-delay: .3s;
}

.l_letter:nth-of-type(5) {
  animation-delay: .4s;
}

.l_letter:nth-of-type(6) {
  animation-delay: .5s;
}

.l_letter:nth-of-type(7) {
  animation-delay: .6s;
}

.l_letter:nth-of-type(8) {
  animation-delay: .7s;
}

.l_letter:nth-of-type(9) {
  animation-delay: .8s;
}

.banner_content {
  background-image: url("bg-banner.84a54549.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--accent);
  place-content: center;
  display: grid;
}

.btn_banner {
  font-size: calc(var(--index) * 1.6);
  color: var(--b);
  will-change: transform;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  transition: transform .4s cubic-bezier(.075, .82, .165, 1);
}

.btn_banner:hover {
  transform: scale(1.1);
}

@media screen and (width <= 1024px) {
  .loggin_header {
    margin-top: calc(var(--index) * 2);
  }

  .loggin_err {
    font-size: calc(var(--index) * 1.5);
  }

  .form__loggin {
    margin: calc(var(--index) * 3) 0;
  }

  .form__loggin input {
    margin-bottom: calc(var(--index) * 2);
  }

  .form__loggin_input img {
    transform: translateY(calc(var(--index) * -1.7));
  }

  .back {
    top: calc(var(--index) * 1.6);
    right: calc(var(--index) * .8);
    font-size: calc(var(--index) * 1.2);
  }

  .banner_header {
    height: 65vh;
  }

  .banner_content {
    height: 50vh;
  }

  .banner_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
  }

  .logo_text {
    display: none;
  }

  .loggin {
    color: var(--accent);
    font-size: calc(var(--index) * 1.6);
    top: calc(var(--index) * .8);
    right: calc(var(--index) * 1.5);
  }
}

.services {
  background-color: var(--w);
  padding: calc(var(--index) * 7) calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 1.5);
  box-sizing: border-box;
  height: 100vh;
  display: grid;
  position: relative;
  overflow: scroll;
}

.services::-webkit-scrollbar {
  width: 0;
}

.services_item {
  font-size: calc(var(--index) * 2.1);
  font-family: var(--bold);
  border-radius: calc(var(--index));
  padding: var(--index) calc(var(--index) * 2);
  margin: calc(var(--index) / 5) 0;
  line-height: calc(var(--index) * 2.9);
  letter-spacing: calc(var(--index) / 10 * -1);
  cursor: pointer;
  will-change: transition;
  align-content: center;
  align-items: center;
  transition: background .4s ease-in;
  display: flex;
}

.services_item > div {
  padding: calc(var(--index) / 2) 0;
}

.services_item:hover {
  background: var(--blue);
}

.services_item img {
  width: calc(var(--index) * 2.5);
  margin-right: var(--index);
}

.active, .active:hover {
  background-color: var(--gb);
}

.services_txt {
  top: calc(var(--index) * 1.5);
  left: calc(var(--index) * 1.5);
  position: absolute;
}

.services_item__content {
  grid-template-areas: "first second"
                       "third third";
  width: 100%;
  height: 100%;
  display: none;
}

.active_content {
  grid-template-rows: 2fr 3fr;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.cell {
  place-items: center;
  display: grid;
}

.cell_first {
  background-color: var(--gb);
  grid-area: first;
}

.cell_first img {
  width: calc(var(--index) * 8);
}

.cell_second {
  background-color: var(--o);
  font-size: calc(var(--index) * 2.1);
  font-family: var(--bold);
  grid-area: second;
}

.cell_second span {
  text-align: center;
}

.cell_third {
  background-color: var(--y);
  padding: calc(var(--index) * 1.5);
  box-sizing: border-box;
  grid-area: third;
  align-content: center;
  width: 100%;
  height: 100%;
}

.cell_third h2 {
  font-family: var(--bold);
  margin-bottom: calc(var(--index) * 1.5);
}

@media screen and (width <= 1024px) {
  .services_item {
    font-size: calc(var(--index) * 2.3);
  }

  .active_content {
    min-height: 60vh;
  }
}

.solutions_header {
  padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
  background-color: var(--b);
  box-sizing: border-box;
  align-content: space-between;
  height: auto;
  display: grid;
  position: relative;
}

.solutions_header h2 {
  top: calc(var(--index) * 1.5);
  position: sticky;
}

.solutions_header__text {
  width: calc(var(--index) * 15);
  font-family: var(--text);
  line-height: calc(var(--index) * 1.1);
  margin-bottom: calc(var(--index) * -3);
}

.solutions {
  grid-gap: var(--index);
  background-color: var(--lb);
  padding: calc(var(--index) * 1.5);
  box-sizing: border-box;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
}

.container_solution__items {
  height: calc(var(--index) * 10);
  border-radius: calc(var(--index) * .2);
  width: 100%;
  overflow: hidden;
}

.container_solution__items img {
  margin: calc(var(--index) * .5) auto;
  max-width: 80%;
  height: 65%;
  display: block;
}

.container_solution__items > div {
  margin: calc(var(--index) * .3) auto;
  color: var(--w);
  text-align: center;
  font-size: calc(var(--index) * 1.5);
  width: 90%;
}

.financial {
  background-color: var(--gb);
  grid-area: 1 / 1 / 2 / 2;
}

.healthcare {
  background-color: var(--y);
  grid-area: 1 / 2 / 2 / 3;
}

.products {
  background-color: var(--v);
  grid-area: 2 / 2 / 3 / 3;
}

.resources {
  background-color: var(--o);
  grid-area: 2 / 3 / 2 / 4;
}

.technology {
  background-color: var(--y);
  grid-area: 3 / 1 / 4 / 2;
}

.education {
  background-color: var(--gb);
  grid-area: 3 / 2 / 4 / 3;
}

@media screen and (width <= 1024px) {
  .solutions_header h2 {
    min-height: 50vh;
    position: static;
  }

  .solutions_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
  }
}

.about_header {
  padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 6) calc(var(--index) * 1.5);
  background-color: var(--b);
  box-sizing: border-box;
  align-content: space-between;
  height: auto;
  display: grid;
  position: relative;
}

.about_header h2 {
  top: calc(var(--index) * 1.5);
  position: sticky;
}

.about_header__text {
  width: calc(var(--index) * 15);
  font-family: var(--text);
  line-height: calc(var(--index) * 1.1);
  margin-bottom: calc(var(--index) * -5);
}

.about_header__text span {
  width: calc(var(--index) * 1);
  height: calc(var(--index) * 1);
  background-color: var(--o);
  margin-right: calc(var(--index) * .51);
  display: inline-block;
}

.about_header__text p {
  display: inline-block;
}

.about_header__text span:first-child {
  background-color: var(--v);
}

.about_header__text span:nth-child(3) {
  background-color: var(--o);
}

.about_header__text span:nth-child(5) {
  background-color: var(--dgb);
}

.about_header__text span:nth-child(7) {
  background-color: var(--lb);
}

.about {
  background-color: var(--lb);
  align-content: end;
  justify-items: center;
  height: 80vh;
  display: grid;
  position: relative;
  overflow: hidden;
}

.about_rect {
  width: calc(var(--index) * 15);
  height: calc(var(--index) * 25);
  background-color: var(--y);
  border-radius: calc(var(--index) * 5);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.about_circle {
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 10);
  background-color: var(--o);
  z-index: 1;
  border-radius: 50%;
  position: absolute;
  top: 60%;
  left: 30%;
  transform: translate(-50%, 10%);
}

.about_image {
  height: calc(var(--index) * 25);
  z-index: 2;
}

@media screen and (width <= 1024px) {
  .about_header {
    grid-area: 1 / 1 / 2 / 2;
  }

  .about_header h2 {
    min-height: 50vh;
    position: static;
  }

  .about {
    background-color: var(--w);
    grid-area: 2 / 1 / 3 / 2;
    height: auto;
  }

  .about_circle {
    width: calc(var(--index) * 20);
    height: calc(var(--index) * 20);
  }

  .about_rect {
    width: calc(var(--index) * 25);
    height: calc(var(--index) * 40);
  }

  .about_image {
    height: calc(var(--index) * 40);
  }

  .about_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
  }
}

@media screen and (width <= 600px) {
  .about_rect {
    width: calc(var(--index) * 25);
    height: calc(var(--index) * 40);
  }
}

.contact_header {
  padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
  background-color: var(--b);
  box-sizing: border-box;
  align-content: space-between;
  height: auto;
  display: grid;
  position: relative;
}

.contact_header h2 {
  top: calc(var(--index) * 1.5);
  position: sticky;
}

.contact_header > .title > span {
  letter-spacing: calc(var(--index) * -.1);
}

.contact_header__text {
  font-family: var(--bold);
  font-size: calc(var(--index) * 3.6);
  width: calc(var(--index) * 25);
  line-height: calc(var(--index) * 2.9);
  letter-spacing: calc(var(--index) / 10 * -1);
  margin-bottom: calc(var(--index) * -3);
}

.contact_header__text span {
  color: var(--o);
}

.section_form {
  padding: calc(var(--index) * 1.5);
  background-color: var(--w);
}

form {
  grid-gap: calc(var(--index) * 5);
  align-content: space-between;
  width: 100%;
  height: 100%;
  display: grid;
}

.input_contact {
  padding: calc(var(--index) * .5);
  margin-bottom: calc(var(--index) * .5);
  border-radius: calc(var(--index) * .3);
  background-color: var(--lb);
  display: grid;
}

label {
  color: var(--o);
  margin-bottom: calc(var(--index) * .2);
  font-family: var(--bold);
  font-size: calc(var(--index) * .8);
  padding-left: calc(var(--index) * .8);
  display: block;
}

input, textarea {
  font-family: var(--bold);
  font-size: calc(var(--index) * 1);
  padding: calc(var(--index) * .5) calc(var(--index) * .8);
  border: 0 solid var(--accent);
  margin-bottom: calc(var(--index) * .8);
  background-color: #0000;
}

.input_contact:active, .input_contact:hover, .input_contact:focus {
  outline: calc(var(--index) * .15) solid var(--o);
}

.submit {
  grid-template-columns: 57% 1fr;
  align-items: end;
  width: 100%;
  display: grid;
}

.form_btn {
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 5);
  background-color: var(--o);
  font-size: calc(var(--index) * 2.1);
  font-family: var(--bold);
  border-radius: calc(var(--index) * .2);
  cursor: pointer;
  color: var(--accent);
  border: none;
  justify-self: end;
  transition: all .3s ease-in;
}

.form_btn:hover {
  background-color: #f88b3f;
}

.label_checkbox {
  font-family: var(--text);
  grid-gap: var(--index);
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0;
  display: grid;
  position: relative;
}

.label_checkbox > span {
  color: var(--accent);
}

.label_checkbox > span > a {
  text-decoration: underline;
}

.checkbox {
  width: calc(var(--index) * 3.5);
  height: calc(var(--index) * 3.5);
  background-color: var(--lb);
  border-radius: calc(var(--index) * .2);
  cursor: pointer;
}

.checkbox:active, .checkbox:hover, .checkbox:focus {
  outline: calc(var(--index) * .15) solid var(--o);
}

.checkbox_checked {
  background-image: url("checkmark.e12827b2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
}

#checkbox {
  opacity: 0;
  z-index: -1;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(0%, -50%);
}

@media screen and (width <= 1024px) {
  .contact_header {
    height: auto;
  }

  .contact_header h2 {
    position: static;
    top: 0;
  }

  .contact_header__text {
    font-size: calc(var(--index) * 4.5);
    width: calc(var(--index) * 30);
    line-height: calc(var(--index) * 4);
    margin-top: calc(var(--index) * 4);
  }

  .section_form {
    background-color: var(--y);
  }

  form {
    grid-gap: calc(var(--index) * 1.5);
  }

  label {
    font-size: calc(var(--index) * 1);
  }

  input, textarea {
    font-size: calc(var(--index) * 1.5);
  }

  .submit {
    grid-gap: calc(var(--index) * 2);
    grid-template-columns: 1fr;
  }
}

.section_block {
  background-color: var(--accent);
  min-height: 60vh;
}

.block_header {
  box-sizing: border-box;
  height: auto;
}

.block_header h2 {
  color: var(--l);
  width: calc(var(--index) * 30);
}

.block {
  padding: calc(var(--index) * 1.5);
  box-sizing: border-box;
  grid-gap: calc(var(--index) * 1);
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  align-items: center;
  display: grid;
}

.blocks_item {
  color: var(--b);
  border-radius: calc(var(--index) * 3);
  padding: calc(var(--index) * .8);
  box-sizing: border-box;
}

.blocks_item p {
  letter-spacing: calc(var(--index) * -.05);
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
}

.block div:first-child {
  background-color: var(--v);
  height: calc(var(--index) * 10);
}

.block div:nth-child(2) {
  background-color: var(--dgb);
  height: calc(var(--index) * 15);
}

.block div:nth-child(3) {
  background-color: var(--o);
  height: calc(var(--index) * 20);
}

@media screen and (width <= 1024px) {
  .block {
    grid-template-columns: 1fr;
  }

  .block_header h2 {
    width: calc(var(--index) * 25);
  }

  .blocks_item {
    font-size: calc(var(--index) * 1.6);
    padding: calc(var(--index) * 1.2);
    width: 50vw;
    min-height: 18vh;
  }

  .block div:nth-child(2) {
    height: calc(var(--index) * 15);
    justify-self: end;
  }

  .block div:nth-child(3), .block div:first-child {
    height: calc(var(--index) * 15);
  }
}

.work_header {
  background-color: var(--w);
  padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
  box-sizing: border-box;
  align-content: space-between;
  height: auto;
  display: grid;
}

.work_header h2 {
  top: calc(var(--index) * 1.5);
  position: sticky;
}

.work_header__text {
  width: calc(var(--index) * 15);
  font-family: var(--text);
  line-height: calc(var(--index) * 1.1);
  margin-bottom: calc(var(--index) * -3);
}

.workflow {
  padding: calc(var(--index) * 1.5);
  background-color: var(--accent);
  color: var(--lb);
  grid-column-gap: calc(var(--index) * 1);
  grid-row-gap: calc(var(--index) * 2);
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: 1fr auto 1fr;
  min-height: 60vh;
  display: grid;
}

.work_item__one {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
}

.work_item__two {
  grid-area: 2 / 3 / 3 / 4;
}

.work_item__three {
  text-align: right;
  grid-area: 3 / 1 / 4 / 2;
  justify-self: end;
}

.work_item__four {
  grid-area: 4 / 3 / 5 / 4;
}

.work_item__five {
  grid-area: 5 / 1 / 6 / 2;
  justify-self: end;
}

.work_item__six {
  grid-area: 6 / 3 / 7 / 4;
}

.work_item__block {
  width: calc(var(--index) * 3);
  height: calc(var(--index) * 3);
  border-radius: calc(var(--index) * .2);
  z-index: 1;
  color: var(--accent);
  font-family: var(--bold);
  font-size: calc(var(--index) * 1.5);
  place-items: center;
  display: grid;
}

.item_blick__one {
  background-color: var(--o);
  grid-area: 1 / 2 / 2 / 3;
}

.item_blick__two {
  background-color: var(--b);
  grid-area: 2 / 2 / 3 / 3;
}

.item_blick__three {
  background-color: var(--dgb);
  grid-area: 3 / 2 / 4 / 3;
}

.item_blick__four {
  background-color: var(--y);
  grid-area: 4 / 2 / 5 / 3;
}

.item_blick__five {
  background-color: var(--v);
  grid-area: 5 / 2 / 6 / 3;
}

.item_blick__six {
  background-color: var(--gb);
  grid-area: 6 / 2 / 7 / 3;
}

.work_item__line {
  background-color: var(--o);
  width: calc(var(--index) * .2);
  grid-area: 1 / 2 / 7 / 3;
  justify-self: center;
  height: 90%;
}

@media screen and (width <= 1024px) {
  .workflow {
    grid-template-columns: auto auto 1fr;
    align-items: center;
  }

  .work_header h2 {
    min-height: 50vh;
    position: static;
  }

  .work_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
  }

  .work_item__one {
    grid-area: 1 / 3 / 2 / 4;
    justify-self: start;
  }

  .work_item__three {
    text-align: left;
    grid-area: 3 / 3 / 4 / 4;
    justify-self: start;
  }

  .work_item__five {
    grid-area: 5 / 3 / 6 / 4;
    justify-self: start;
  }
}

.certifications_header {
  box-sizing: border-box;
  padding: calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 4.5) calc(var(--index) * 1.5);
  align-content: space-between;
  height: auto;
  display: grid;
}

.certifications_header h2 {
  top: calc(var(--index) * 1.5);
  position: sticky;
}

.certification_header__text {
  width: calc(var(--index) * 15);
  font-family: var(--text);
  line-height: calc(var(--index) * 1.1);
  margin-bottom: calc(var(--index) * -3.5);
  color: var(--w);
}

.wrapper_certifications {
  grid-gap: calc(var(--index) * .8);
  padding: calc(var(--index) * 1.5);
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr 1fr;
  height: 70vh;
  display: grid;
  overflow: scroll;
}

.block_certificate {
  grid-gap: calc(var(--index) * .3);
  grid-template-columns: auto 1fr;
  place-items: center start;
  display: grid;
}

.check_certificate {
  width: calc(var(--index) * 2.5);
  height: calc(var(--index) * 2.5);
  background-color: var(--o);
  border-radius: calc(var(--index) * .2);
  place-items: center;
  display: grid;
}

.certificate {
  color: var(--lb);
}

.certificate_check {
  width: 60%;
}

@media screen and (width <= 600px) {
  .wrapper_certifications {
    grid-template-columns: 1fr;
  }
}

@media screen and (width <= 1024px) {
  .certifications_header {
    background-color: var(--y);
  }

  .certifications_header h2 {
    color: var(--accent);
    min-height: 50vh;
    position: static;
  }

  .certification_header__text {
    width: calc(var(--index) * 20);
    line-height: calc(var(--index) * 1.5);
    color: var(--accent);
  }
}

footer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.c_footer {
  grid-template-columns: 1fr 1fr;
  height: auto;
  min-height: 60vh;
  display: grid;
  overflow: hidden;
}

.c_footer__block {
  padding: calc(var(--index) * 1.5);
  align-content: space-between;
  justify-items: start;
  display: grid;
}

.c_footer__block__header {
  margin: 0;
}

.c_footer__block__sub {
  font-style: italic;
  font-family: var(--bold);
  font-size: calc(var(--index) * 1.3);
}

.contacts {
  background-color: var(--v);
}

.contacts .c_footer__block__sub {
  color: var(--l);
}

.contacts_item img {
  width: calc(var(--index) * 4.6);
}

.email {
  font-family: var(--bold);
  font-size: calc(var(--index) * 1.8);
  line-height: calc(var(--index) * 1.4);
}

.social {
  background-color: var(--b);
}

.social .c_footer__block__sub {
  color: var(--dgb);
}

.social_item img {
  width: calc(var(--index) * 3);
  margin-top: calc(var(--index) / -2);
}

.social_media {
  grid-column-gap: var(--index);
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.social_item_icon {
  width: calc(var(--index) * 2);
  height: calc(var(--index) * 2);
  border: solid calc(var(--index) / 5) var(--accent);
  border-radius: calc(var(--index) / 5);
  align-items: center;
  display: flex;
}

.social_item_icon img {
  width: calc(var(--index) * 2);
  height: calc(var(--index) * 2);
  margin: 0;
  display: block;
}

#linkedin {
  width: calc(var(--index) * 1.5);
  height: calc(var(--index) * 1.5);
  margin: 0 auto;
}

.m_footer {
  background-color: var(--l);
  align-content: space-between;
  height: 80vh;
  display: grid;
}

.m_footer__nav {
  padding: calc(var(--index) * 1.5);
  justify-content: space-between;
  display: flex;
}

.m_footer__letter {
  padding: calc(var(--index) * 1.5) 0;
  box-sizing: border-box;
  justify-content: center;
  width: 100vw;
  display: flex;
}

.footer_link {
  font-family: var(--bold);
  font-size: calc(var(--index) * 1.6);
  line-height: calc(var(--index) * 1.8);
  letter-spacing: calc(var(--index) / -20);
  text-decoration: underline;
}

.letter {
  font-family: var(--bold);
  letter-spacing: calc(var(--index) * -.1);
  font-size: 20vw;
  line-height: calc(var(--index) * 10);
  opacity: 0;
  animation-duration: .6s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  display: inline-block;
  position: relative;
}

.anim {
  animation-name: animate;
}

.letter:nth-of-type(2) {
  animation-delay: .1s;
}

.letter:nth-of-type(3) {
  animation-delay: .2s;
}

.letter:nth-of-type(4) {
  animation-delay: .3s;
}

.letter:nth-of-type(5) {
  animation-delay: .4s;
}

.letter:nth-of-type(6) {
  animation-delay: .5s;
}

.letter:nth-of-type(7) {
  animation-delay: .6s;
}

.letter:nth-of-type(8) {
  animation-delay: .7s;
}

.letter:nth-of-type(9) {
  animation-delay: .8s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0)rotateX(-90deg);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, -10%, 0)rotateX(0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0)rotateX(0);
  }
}

@media screen and (width <= 1024px) {
  .c_footer {
    grid-template-columns: 1fr;
    min-height: 100vh;
  }

  .email {
    font-size: calc(var(--index) * 2.7);
  }

  .contacts_item img {
    width: calc(var(--index) * 7.5);
  }

  .social_item img {
    width: calc(var(--index) * 5);
  }

  .social_item_icon, .social_item_icon img {
    width: calc(var(--index) * 4);
    height: calc(var(--index) * 4);
  }

  #linkedin {
    width: calc(var(--index) * 2.2);
    height: calc(var(--index) * 2.2);
  }

  .c_footer__block__sub {
    font-size: calc(var(--index) * 1.8);
  }

  .m_footer {
    grid-gap: calc(var(--index) * 5);
    height: auto;
  }

  .m_footer__nav {
    column-count: 2;
    display: block;
  }

  .footer_link {
    line-height: calc(var(--index) * 3);
  }

  .letter {
    line-height: calc(var(--index) * 6);
  }
}

.privacy_wrapper {
  background-color: var(--l);
  padding: calc(var(--index) * .8) calc(var(--index) * 1.5) calc(var(--index) * 1.5) calc(var(--index) * 1.5);
  min-height: 100vh;
}

.privacy {
  margin: calc(var(--index) * 5) auto;
  width: min(750px, 80vw);
}

.breadcrumb div {
  display: inline;
}

.br_home a {
  color: var(--o);
  text-decoration: underline;
}

.br {
  opacity: .5;
}

.privacy .title {
  margin: calc(var(--index) * 2) 0 calc(var(--index) * 3) 0;
  width: auto;
}

.privacy h3 {
  margin: calc(var(--index) * 1.2) 0 calc(var(--index) * 1) 0;
}

.privacy p {
  margin: calc(var(--index) * .8) 0;
}

.wrapper_error {
  background-color: var(--y);
  box-sizing: border-box;
  padding: calc(var(--index) * 1.5);
  align-content: center;
  width: 100vw;
  height: 100vh;
  display: grid;
}

.error {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.error_title {
  font-family: var(--bold);
  font-size: calc(var(--index) * 7);
}

.error_image img {
  height: calc(var(--index) * 26);
}

.error_btn {
  width: calc(var(--index) * 15);
  height: calc(var(--index) * 5);
  background-color: var(--o);
  font-size: calc(var(--index) * 2.1);
  font-family: var(--bold);
  border-radius: calc(var(--index) * .2);
  cursor: pointer;
  letter-spacing: calc(var(--index) / 10 * -1);
  border: none;
  justify-self: end;
  place-items: center;
  transition: all .3s ease-in;
  display: grid;
}

@media screen and (width <= 720px) {
  .error {
    grid-gap: calc(var(--index) * 3);
    grid-template-columns: 1fr;
  }

  .error_image {
    justify-items: center;
    display: grid;
  }

  .error_image img {
    height: 40vh;
  }
}
/*# sourceMappingURL=index.2397ca0a.css.map */
